<template>
    <div class="mgd-login">
        <!-- <div class="switch" @click="toZhao"></div> -->
        <div
            class="switch-phone"
            v-show="loginMode === 'wx'"
            @click="loginModeChange('phone')"
        ></div>
        <div
            class="switch-wx"
            v-show="loginMode === 'phone'"
            @click="loginModeChange('wx')"
        ></div>

        <div v-show="loginMode === 'wx'" style="text-align: center">
            <p class="wx-title">微信扫一扫登录</p>
            <div class="wxcode-box">
                <!-- <wxlogin
                    href="data:text/css;base64,LmxvZ2luUGFuZWwgLnRpdGxle2Rpc3BsYXk6bm9uZX0KLndhaXRpbmcgLmluZm97ZGlzcGxheTpub25lfQouaW1wb3dlckJveCAucXJjb2RleyAgICAKICAgIHdpZHRoOjEwLjM3NXJlbTsKICAgIG1hcmdpbi10b3A6IDA7CiAgICBib3JkZXI6IG5vbmU7Cn0KLm9sZC10ZW1wbGF0ZXsKd2lkdGg6IDE2NnB4O2hlaWdodDogMTY2cHg7Cn0="
                    id="wxcode"
                    theme=""
                    appid="wxb00e2875006b2a74"
                    scope="snsapi_login"
                    loginType="wechatcompany"
                    :redirect_uri="
                        encodeURIComponent(
                            `http://zhaopin.moguding.net/login?uuid=${this.uuid}&thirdpart=wechatcompany${this.wxCode}`
                        )
                    "
                ></wxlogin> -->
                <div v-show="qrCodeState === 'loading'" class="mask mask-loading"><i class="el-icon-loading"></i></div>
                <div v-show="qrCodeState === 'success'" class="mask mask-success"><i class="el-icon-circle-check"></i></div>
                <div v-show="qrCodeState === 'error'" class="mask mask-error" @click="qrCodeRefresh"><i class="el-icon-circle-close"></i></div>
                <div v-show="qrCodeState === 'refresh'" class="mask mask-refresh" @click="qrCodeRefresh">
                    <i class="el-icon-refresh-right"></i>
                </div>
                <img v-if="qrCodeUrl" :src="qrCodeUrl" alt="二维码" />
            </div>
        </div>

        <div v-show="loginMode === 'phone'" class="form">
            <p class="phone-title">我要招人</p>
            <el-form
                ref="SmsForm"
                :model="SmsForm"
                :rules="smsRules"
                size="large"
                auto-complete="on"
                label-position="left"
                @keyup.enter.native="handleLogin"
            >
                <el-form-item prop="username">
                    <el-input
                        v-model="SmsForm.phone"
                        placeholder="请输入手机号"
                        name="username"
                        type="text"
                        auto-complete="on"
                        :class="inpcss == 0 ? 'inpfocus' : 'inpblur'"
                        @focus="test(0)"
                    />
                </el-form-item>

                <el-form-item prop="password" class="relative">
                    <el-input
                        v-model="SmsForm.msgCaptcha"
                        type="text"
                        placeholder="请输入验证码"
                        name="password"
                        auto-complete="on"
                        :class="inpcss == 1 ? 'inpfocus' : 'inpblur'"
                        @keyup.enter.native="handleLogin"
                        @focus="test(1)"
                    />
                    <div class="butyzm" @click="getSmsCodeFn">
                        {{ content }}
                    </div>
                </el-form-item>
                <!--              <el-form-item prop="captcha">-->
                <!--                <el-col :span="14">-->
                <!--                  <el-input v-model="SmsForm.captcha" placeholder="图片验证码" auto-complete="on" type="text"-->
                <!--                    :class="inpcss==2?'inpfocus':'inpblur'" @focus="test(2)" />-->
                <!--                </el-col>-->
                <!--                <el-col :span="10">-->
                <!--                  <img class="codeimg" :src="captchaPathSms" alt="加载失败" @click="getCaptchaSms()">-->
                <!--                </el-col>-->
                <!--              </el-form-item>-->
                <el-form-item>
                    <el-button
                        class="login-btn"
                        :loading="loading"
                        type="primary"
                        style="width: 100%; margin-bottom: 15px"
                        @click.native.prevent="handleLoginSms"
                        >登录/注册
                    </el-button>
                </el-form-item>

                <el-form-item
                    type="flex"
                    class="row-bg"
                    justify="space-between"
                    style="margin: -10px 0 4px 0px"
                >
                    <el-checkbox v-model="agreement" class="agreement">
                        <span class="txt">已阅读并同意</span>
                        <a target="_blank" class="tips" :href="serviceProtocol"
                            >《用户服务协议》</a
                        ><span class="txt"> 和 </span
                        ><a
                            target="_blank"
                            class="tips"
                            :href="propertyProtocol"
                            >《隐私政策》</a
                        >
                    </el-checkbox>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import wxlogin from "vue-wxlogin";
import { getJumpUrl, getServerUrl, encryptCode, getUserSmsCode, getWxCode, loginUserSms } from "@/api/login";

export default {
  name: "mgd-login",
  components: {
    wxlogin,
  },
  data() {
    const validateRegUsername = (rule, value, callback) => {
      if (!this.isPoneAvailable(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      propertyProtocol: "https://www.moguding.net/legal/html/device/privacy.html", // 隐私协议
      serviceProtocol: "https://www.moguding.net/legal/html/device/customer.html ", // 服务协议
      loginMode: 'wx',
      uuid: "",
      inpcss: -1,
      loading: false,
      agreement: false,
      judgereg: false,
      content: "发送验证码",
      totalTime: 60,
      getVerificationCode: false,

      smsRules: {
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: validateRegUsername,
          },
        ],
        captcha: [
          {
            required: true,
            trigger: "blur",
            message: "请输入图片验证码",
          },
        ],
      },

      SmsForm: {
        phone: "",
        msgCaptcha: "",
        captcha: "",
      },
      qrCodeUrl: null, // 二维码链接
      qrCodeState: null, //二维码状态
      qrCodeinterval: null,
      websockLink: null,
    };
  },
  mounted() {
    // const uuid = require("uuid");
    // this.uuid = uuid.v4();
    this.getQRCode();
  },
  //监听路由可以检测返回的code
  // watch: {
  //   $route: {
  //     handler: function (route) {
  //       this.wxCode = this.$route.query.code || "";
  //       //本地存储code是因为从其他页面返回vue页面存在缓存需要自定义刷新
  //       if (this.wxCode == localStorage["wxCode"] && this.wxCode != "") {
  //         window.location.href = this.redirect_uri; //回调地址
  //       } else {
  //         localStorage.setItem("wxCode", this.wxCode); //设置b为"isaac"
  //       }
  //       if (this.wxCode) {
  //         let params = {
  //           code: this.wxCode,
  //           state: "",
  //         };
  //         wechatLogin(params)
  //           .then((res) => {
  //             if (res.status) {
  //               //返回参数如果绑定微信返回token正常存储登录未绑定账号返回unionid绑定当然也可以以微信信息为主题自动生成账号
  //             }
  //           })
  //           .catch(() => {
  //             //返回失败因为二维码已经被使用过所以需要刷新重新获取
  //             window.location.href = this.redirect_uri;
  //           });
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    loginModeChange(mode) {
      this.loginMode = mode;
    },
    getQRCode() {
      this.qrCodeState = 'loading';
      getWxCode({}).then((resp) => {
        // console.log('resp', resp);
        if (resp.code === 200) {
          const { qrCodeUrl, socketKey, periodOfValidity } = resp.data;
          this.qrCodeUrl = qrCodeUrl;

          this.initWebSocket(socketKey);
          setTimeout(() => {
            this.qrCodeState = "refresh";
            if (this.websockLink) {
              this.clearWebSocket();
            }
          }, periodOfValidity * 1000);
        } else {
          this.qrCodeState = 'error';
        }
      }).catch(err => {
        this.qrCodeState = 'error';
      });
    },
    // 扫码登录
    initWebSocket(socketKey) {
      let wsuri = getServerUrl() + "/websocket/message/" +
        socketKey;
      if (wsuri.substring(0, wsuri.indexOf(':')) === 'http') {
        wsuri = wsuri.replace('http', 'ws')
      } else {
        wsuri = wsuri.replace('https', 'wss')
      }

      this.websockLink = new WebSocket(wsuri);
      this.websockLink.onmessage = (message) => {
        this.qrCodeState = 'success'
        const data = JSON.parse(message.data);
        this.clearWebSocket();
        this.onJumpManageBackground(data.token, data.userType, data)
      };
      this.websockLink.onopen = () => {
        this.qrCodeState = null
        // console.log("WebSocket连接成功");
        const ws = this.websockLink;
        this.qrCodeinterval = setInterval(() => {
          ws.send("client call~~~~~");
        }, 3000);
      };
      this.websockLink.onerror = (err) => {
        // console.log("WebSocket连接发生错误", err);
        this.qrCodeState = 'error';
        this.clearWebSocket();
      };
      this.websockLink.onclose = (err) => {
        // console.log("断开连接", err);
        this.qrCodeState = 'error';
        this.clearWebSocket();
      };
    },
    clearWebSocket() {
      if (this.websockLink) {
        this.websockLink.close();
        this.websockLink = null;
        clearInterval(this.qrCodeinterval);
      }
    },
    test(i) {
        this.inpcss = i;
    },
    qrCodeRefresh() {
      if (this.qrCodeState === 'loading') {
        return;
      }
      this.clearWebSocket();
      this.getQRCode();
    },

    // 验证手机号
    isPoneAvailable(poneInput) {
      var myreg = /^[1][0-9][0-9]{9}$/;
      if (!myreg.test(poneInput)) {
        return false;
      } else {
        return true;
      }
    },

    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (!this.agreement) {
            this.$message({
              message: "请阅读并接受蘑菇丁《服务协议》与《隐私政策》",
              type: "error",
              duration: 1500,
            });
            return;
          }
          this.toLogin();
        } else {
          return false;
        }
      });
    },

    getSmsCodeFn() {
      if (this.getVerificationCode) {
        return;
      }

      if (!this.isPoneAvailable(this.SmsForm.phone)) {
        this.$message.error("请输入正确手机号");
        return;
      }
      // if (this.SmsForm.captcha === '') {
      //   this.$message.error('请输入图片验证码')
      //   return
      // }
      const param = {
        phone: this.SmsForm.phone,
        type: 1,
        loginType: "web",
      };
      param.type = 6;
      getUserSmsCode(param).then((data) => {
        if (data.msg === '成功') {
          this.$message({
            message: "验证码已发送！",
            type: "success",
          });
        } else {
          this.$message({
            message: data.msg,
            type: "info",
          });
        }
      });
      this.judgereg = false;

      this.getVerificationCode = true;
      this.content = this.totalTime + "秒后重新发送";
      const clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "秒后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 10;
          this.getVerificationCode = false;
        }
      }, 1000);
    },

    handleLoginSms() {
      if (!this.isPoneAvailable(this.SmsForm.phone)) {
        this.$message.error("请输入正确手机号");
        return;
      }
      // if (this.SmsForm.captcha === '') {
      //   this.$message.error('请输入图片验证码')
      //   return
      // }
      if (this.SmsForm.msgCaptcha === "") {
        this.$message.error("请输入短信验证码");
        return;
      }
      if (!this.agreement) {
        this.$message({
          message: "请阅读并接受蘑菇丁《服务协议》与《隐私政策》",
          type: "error",
          duration: 1500,
        });
        return;
      }
      this.$refs.SmsForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            phone: this.SmsForm.phone,
            smsCode: this.SmsForm.msgCaptcha,
            loginType: "web",
            // judgereg: this.judgereg, // true-注册，false-登录
          };
          loginUserSms(params).then(resp => {
            this.loading = false;
            
            if (resp.code === 200) {
              this.onJumpManageBackground(resp.data.token, resp.data.userType, resp.data)
            }
          }).catch(() => {
            this.loading = false;
          })
        }
      });
    },
    // 登录到后台
    onJumpManageBackground(token, userType, query) {
        let url = getJumpUrl();
        const id = encryptCode(query);

        // token存在登录成功
        if (token) {
          // profile 待认证用户, enterprise 企业用户
          if (userType === 'enterprise') {
            url += '/login?source=mgd_index' + '&id=' + id
          } else {
            url += '/invitationRegister?source=mgd_index' + '&id=' + id
          }
        } else {
          // token为null 表示用户不存在, 跳转至绑定手机号页面
          url += '/bindingAccount?source=mgd_index' + '&id=' + id;
        }

        location.href = url;
        // window.open(url)
    },
  },
};
</script>

<style lang="less" scoped>
.login .switch {
    width: 10.75rem;
    height: 4rem;
    background: url("../assets/home_img/mmSignin.png") no-repeat;
    background-size: cover;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    cursor: pointer;
}

.login {
    .switch-phone,
    .switch-wx {
        width: 42px;
        height: 42px;
        background-size: cover;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: -1;
        cursor: pointer;
    }
    .switch-phone {
        background: url("../assets/home_img/mmSignin2.png") no-repeat;
        background-size: 42px 42px;
    }
    .switch-wx {
        background: url("../assets/home_img/mmSignin3.png") no-repeat;
        background-size: 42px 42px;
    }
    .wx-title {
        font-size: 14px;
        color: #333333;
        margin-bottom: 25px;
        display: inline-block;
    }
    .phone-title {
        font-size: 20px;
        font-weight: bold;
        color: #222222;
        text-align: left;
        margin-bottom: 25px;
    }
    .wxcode-box {
        width: 166px;
        height: 166px;
        border: 1px solid #eaecf4;
        border-radius: 24px;
        overflow: hidden;
        padding: 5px;
        margin: 0 auto;
        position: relative;
        img,
        .mask {
            width: 100%;
            height: 100%;
        }
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.95;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            i {
                font-size: 36px;
                font-weight: bold;
            }
            &.mask-error {
              color: #F56C6C;
            }
            &.mask-success {
              color: #67C23A;
            }
        }
    }
}
.mgd-login {
    width: 100%;
    box-sizing: border-box;
    &.p_t_30 {
        padding-top: 30px;
    }
    .form {
        padding: 0 50px;
    }
    .relative {
        position: relative;
    }
    .butyzm {
        width: 110px;
        // height: 12px;
        text-align: center;
        font-size: 14px;
        color: #fe8a12;
        position: absolute;
        // margin: -40px 220px;
        top: 0;
        right: 0;
    }
    .butyzm:hover {
        cursor: pointer;
    }
    .row-bg {
        .agreement {
            display: flex;
            align-items: start;
            .txt {
                color: #999999;
            }

            .tips {
                color: #3f6bfc;
                cursor: pointer;
                text-decoration: none;
            }

            :nth-child(2) {
                line-height: 14px;
            }
            :nth-child(2) {
                text-wrap: wrap;
            }
        }
    }
    .inpblur {
        border: 0px solid red;
        border-radius: 5px;
        background: #f3f3f4;
    }

    ::v-deep .el-input__inner:focus {
        border-color: #3f6bfc;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #fe8a12;
        border-color: #fe8a12;
    }
    .login-btn {
        background-color: #fe8a12;
        border-color: transparent;
    }
}
</style>
